import React from 'react';
import BlogSectionWrapper from 'src/components/shared/wraps/BlogSectionWrapper/BlogSectionWrapper';
import { graphql } from 'gatsby';
import Layout from 'src/layouts/Layout';
import HtmlParser, { convertNodeToElement } from 'react-html-parser';
import Section from 'src/components/shared/wraps/Section/Section';
import translate from 'src/utils/translate';
import SEO from 'src/components/seo';
import styled, { css } from 'styled-components';
import BigTitle from 'src/components/shared/text/BigTitle';
import ParagraphShort from 'src/components/shared/text/ParagraphShort';
import Content from 'src/components/shared/Content';
import FadeLink from 'src/components/shared/navigations/FadeLink/FadeLink';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FacebookShareButton, PinterestShareButton } from 'react-share';
import { faFacebookF, faPinterestP } from '@fortawesome/free-brands-svg-icons';
import { Title, Date } from 'src/components/Sites/Blog/Post/Post';
import {
  RotatingLetter,
  NoWrap,
} from 'src/components/shared/Animations/Animations';
import useLng from "../hooks/useLng";
import {useI18next} from "gatsby-plugin-react-i18next";

const hoverMixin = css`
  transition: color 0.2s;
  &:hover {
    color: ${({ theme }) => theme.colors.main};
  }
`;

const disabledMixin = css`
  color: ${({ theme }) => theme.colors.gray1};
  pointer-events: none;
`;

const StyledTitlePost = styled(Title)`
  opacity: 0.7;
  text-align: left;
  line-height: 1;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 767px) {
    font-size: 4rem;
  }
`;

const StyledContent = styled(Content)`
  margin: 3rem auto;
`;

const StyledList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
      color: currentColor;
    }
  }

  li {
    margin-right: 1.5rem;
  }
`;

const StyledListItem = styled(ParagraphShort)`
  opacity: 1;
  line-height: unset;
  font-size: 1.4rem;
`;

const StyledFadeLink = styled(FadeLink)`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 'inherit')};
  ${({ disabled }) =>
    !disabled
      ? css`
          transition: color 0.2s;
          &:hover {
            color: ${({ theme }) => theme.colors.main};
            ${StyledFontAwesomeIcon} {
              color: ${({ theme }) => theme.colors.main};
            }
          }
        `
      : css`
          ${disabledMixin}
        `};
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.black};
  margin:0.8rem 0 2.8rem 0;
`;

const StyledFlexWrapper = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems && alignItems};
  justify-content: ${({ justifyContent }) => justifyContent && justifyContent};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  margin-bottom: 1rem;

  ${({ columnsBreakpoint }) =>
    columnsBreakpoint &&
    css`
      @media screen and (max-width: 1199px) {
        flex-direction: column;
      }
    `}
`;

const StyledParagraphShort = styled(ParagraphShort)`
  font-weight: ${({ bold }) => (bold ? 700 : 'inherit')};
  margin-right: 0.6rem;
  line-height: unset;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray1 : theme.colors.gray9};
  ${hoverMixin}
`;

const StyledPrevNextWrapper = styled(StyledFlexWrapper)`
  text-align: right;
  flex-shrink: 0;
  justify-content: space-between;
  display: flex;

  @media screen and (max-width: 767px) {
    align-self: center;
    margin-top: 2rem;
  }
`;

const StyledBigTitle = styled(BigTitle)`
  text-align: center;
  width: 100%;
  margin-bottom: 7rem;
  a {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Post = ({
  data,
  location: { pathname: path },
  pageContext,
  transitionStatus,
  entry,
  exit,
}) => {
  const {
    content,
    title,
    created_on,
    categories,
    tags,
    date_time_created,
      translacje
  } = data.post;
  const { previousSlug, nextSlug } = pageContext;
  const filteredTags = tags.filter(tag => tag !== '');
  const categorySlug = categories
    .trim()
    .toLowerCase()
    .replace(/ /g, '-');
  const date = translate(date_time_created || created_on);
  const {language } = useI18next()
  const {t} = useLng()
  const en = translacje.find(el => el.jezyk === "en")
  return (
    <Layout
      path={path}
      id="post"
      nonBgc
      transitionStatus={transitionStatus}
      entry={entry}
      exit={exit}
    >
      <SEO title={`${language === "en" ? en?.title ?? "" : title}`} />
      <Section nonGrid site nonFlex first>
        <StyledBigTitle>
          <FadeLink to="/blog/">
            <NoWrap>
              Blo
              <RotatingLetter delay={0.4}>g</RotatingLetter>
            </NoWrap>
          </FadeLink>
        </StyledBigTitle>
        <BlogSectionWrapper>
          <StyledTitlePost as="h1">{language === "en" ? en?.title ?? "" : title}</StyledTitlePost>
          <Date>{date}</Date>
          <StyledFlexWrapper>
            <StyledParagraphShort bold>{t("kategorie")}</StyledParagraphShort>
            <StyledListItem>
              <StyledFadeLink to={`/kategorie/${categorySlug}`}>
                {categories}
              </StyledFadeLink>
            </StyledListItem>
          </StyledFlexWrapper>
          <StyledContent post>
            {HtmlParser(language === "en" ? en?.content ?? "" :content, {
              transform: function(node) {
                if (node.name === 'a') {
                  return (
                    <a href={node.attribs.href} rel="nofollow">
                      {convertNodeToElement(node.children[0])}
                    </a>
                  );
                }
              },
            })}
          </StyledContent>
          <StyledFlexWrapper column>
            <StyledFlexWrapper alignItems="center" justifyContent="flex-end">
              <StyledParagraphShort>{t("podzielSie")}</StyledParagraphShort>
              <StyledList>
                <li>
                  <FacebookShareButton
                    url={`https://makadu.pl${path}`}
                    quote="Makadu"
                    hashtag="#makadu"
                  >
                    <StyledFontAwesomeIcon icon={faFacebookF} />
                  </FacebookShareButton>
                </li>
                <li>
                  <PinterestShareButton
                    url={`https://makadu.pl${path}`}
                    quote="Makadu"
                    hashtag="#makadu"
                    media="images/makadu.jpg"
                  >
                    <StyledFontAwesomeIcon icon={faPinterestP} />
                  </PinterestShareButton>
                </li>
              </StyledList>
            </StyledFlexWrapper>
          </StyledFlexWrapper>
          <StyledFlexWrapper alignItems="flex-start">
            <StyledList>
              {filteredTags.map(tag => {
                const tagSlug = tag
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .trim()
                  .toLowerCase()
                  .replace(/ /g, '-');
                return (
                  <StyledListItem as="li">
                    <StyledFadeLink to={`/tagi-bloga/${tagSlug}`}>
                      {tag}
                    </StyledFadeLink>
                  </StyledListItem>
                );
              })}
            </StyledList>
          </StyledFlexWrapper>
          <Separator />
          <StyledPrevNextWrapper>
            {previousSlug ? (
              <StyledFadeLink
                to={`/blog/${previousSlug}`}
                fontSize="1.4rem"
                style={{ marginRight: '2rem' }}
              >
                <StyledFontAwesomeIcon
                  icon={faChevronLeft}
                  style={{ marginRight: '1rem' }}
                />
                {t("prev")}
              </StyledFadeLink>
            ) : null}
            {nextSlug ? (
              <StyledFadeLink to={`/blog/${nextSlug}`} fontSize="1.4rem">
                {t("next")}
                <StyledFontAwesomeIcon
                  icon={faChevronRight}
                  style={{ marginLeft: '1rem' }}
                />
              </StyledFadeLink>
            ) : null}
          </StyledPrevNextWrapper>
        </BlogSectionWrapper>
      </Section>
    </Layout>
  );
};

export const postQuery = graphql`
  query postsQuery($id: String!) {
    post: directusPosty(id: { eq: $id }) {
      title
      slug
      content
      created_on
      categories
      tags
      date_time_created
      translacje{
        jezyk
        title
        content
      }
    }
  }
`;

export default Post;
